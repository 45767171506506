import React from "react"
import Layout from '../components/layout'


import SuccessPage from '../components/PageComponents/Success/SuccessPage'


const Success = (props) => (
<Layout>

    <SuccessPage />
  </Layout>
)

export default Success
